import React, { createContext, useState,  useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

export const GetTrackHospitalData = createContext();

const GetContextTrackHospital = ({ children }) => {
    const [hospitalData, setHospitalData] = useState(null);
    const [error, setError] = useState(null);
    const [errorStatus, setErrorStatus] = useState(null);
    const [loading, setLoading] = useState(false); // Added loading state

    const fetchHospitalData = useCallback(async (hospitalId) => {
        setLoading(true); // Start loading
        const token = Cookies.get('adminToken');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}clients/${hospitalId}`, {
                headers: {
                    'Accept': '*/*',
                    'Authorization': `Bearer ${token}`
                }
            });
            setHospitalData(response.data.data);
            setError(null);
            setErrorStatus(null);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false); // End loading
        }
    }, []);

    const handleError = (err) => {
        console.error(err); // Log the error
        if (err?.response?.status === 403) {
            setErrorStatus(403);
            setError('ليس لديك صلاحية الوصول لبيانات المستشفيات.');
        } else {
            setErrorStatus(err?.response?.status || 500);
            setError('حدث خطأ أثناء جلب البيانات.');
        }
    };

    const clearHospitalData = () => {
        setHospitalData(null);
        setError(null);
        setErrorStatus(null);
    };
    
    return (
        <GetTrackHospitalData.Provider
            value={{
                hospitalData,
                error,
                errorStatus,
                loading, // Include loading in the context
                refetchHospitalData: fetchHospitalData,
                clearHospitalData
            }}
        >
            {children}
        </GetTrackHospitalData.Provider>
    );
};

export default GetContextTrackHospital;
