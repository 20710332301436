import { useContext } from 'react';
import Loader from '../../../../hospital/components/Loader';
import { PermissionContext } from '../../../../../../context/admin/PermissionContext';

const AdminPermissions = () => {
    const {permissions,error,loading} = useContext(PermissionContext)
    if(loading){
        return <Loader/>
    }
    
  return (
    <div className='px-4 my-4 w-full'>
        <p className='text-3xl my-4 avenir-heavy text-black setting-heading'>صلاحيات تراك</p>
        <div>
            {error?
      <p className='text-red-500'>{error}</p>
    :(
    permissions && Object.keys(permissions).length > 0 ? (
        <div className='flex flex-col gap-4'>
            {Object.entries(permissions).map(([key, values],index) => (
                <div key={index}>
                <p className='text-xl text-black' >{key}</p>
                <div className='shadow-lg p-3 rounded-md' >
                {values.map((item,index)=>(
                    <div  key={index}>
                        {Object.entries(item).map(([itemKey,itemValue])=>(
                                itemKey === 'name' &&(
                                    <p  className='p-3 my-3 text-white text-sm bg-[--main-color-green] rounded-md' key={item.id}>{itemValue}</p>
                                )
                        ))}
                    </div>
                ))}
                </div>
                </div>
            ))}
        </div>
     ):(
        <p className='text-[--main-color-green]'>لا توجد بيانات لعرضها.</p>
     )
)
            }
    
        </div>
    </div>
  )
}

export default AdminPermissions
