import Links from "./components/Links";
import ListLinks from "./components/ListLinks";
import { Logo } from "./components/Logo";

function Navbar() {
    return (
        <div className="navbar bg-white flex justify-between m-0 max-h-[80px] shadow-md ">
          <Logo/>      
          <div className="lg:navbarend">
            <div className="dropdown">
              <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h8m-8 6h16" />
                </svg>
              </div>
              <ListLinks/>
            </div>
        <div className="navbar-center hidden lg:flex">
            <Links/>
          </div>
          </div>
</div>
);
}

export default Navbar;