import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

export const SpecialtiesContext = createContext();

const SpecialtiesData = ({children}) =>{
    const [specialties,setSpecialties] = useState([]);
    const [errorSpecialties, setErrorSpecialties] = useState(null);
    useEffect(()=>{
        const inialSpecialties = async() =>{
            try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}specialties`);
            
            setSpecialties(res.data.data);
            }catch(err){
                setErrorSpecialties('خطأ في جلب بيانات التخصصات ')
            }
        }
        inialSpecialties();
    },[]);

    return(
        <SpecialtiesContext.Provider
        value={{
            specialties,
            errorSpecialties
        }}
        >
        {children}
        </SpecialtiesContext.Provider>
    )
}
export default SpecialtiesData;