import React, { useEffect, useState } from 'react'

// img
import avatar_icon from '../assets/imgs/avatar.png';

// icons
// import doctor_icon from '../assets/icons/doctors_icon.svg';
// import chart_icon from '../assets/icons/chart_icon.svg';
import logout_icon from '../assets/icons/logout_icon.svg';
import { Link, useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';
import { useConfirmation } from '../../../context/auth/users/ConfirmationProvider';
import axios from 'axios';
import { auth } from '../../../firebase/firebase';

const SignedUser = () => {
  const navigate = useNavigate();
  const { setConfirmationResult } = useConfirmation();
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState('');

  const fetchUserData = async () => {
    try {
        const token = Cookies.get('UserToken');
        if (!token) {
            setError('Token is missing.');
            return;
        }
        const res = await axios.get(`${process.env.REACT_APP_API_URL}users/patient-profile`, {
            headers: {
                'Accept': '*/*',
                'Authorization': `Bearer ${token}`
            }
        });
        setUserData(res.data.data);
    } catch (err) {
        setError('Error fetching data.');
    }
};

useEffect(() => {
    fetchUserData();
}, []);

  const logout = () => {
    Cookies.remove('UserToken');
    setConfirmationResult(null);
     const clearAuthSession = async () => {
          try {
            if (auth.currentUser) {
              await auth.signOut();  // Clears the previous Firebase session
              // console.log("Signed out from previous session.");
            }
          } catch (error) {
            // console.error("Error signing out from Firebase:", error);
          }
        };
        setConfirmationResult(null)
        clearAuthSession();
    navigate('/');
  };
    return (
    <div className='min-w-[250px] lg:min-w-[300px] text-black avenir-heavy '>
      <div className='flex items-center flex-row  gap-2'>
        <img className='w-16 rounded-[50%]' src={userData?.image ? userData.image :avatar_icon} alt='avatar'/>
        <div>
        <p className='text-xl'>أهلا ,{userData?.name}</p>
        <Link className='text-[15px] text-[--main-color-green] avenir-book' to={'/user-profile'}>عرض الملف الشخصي</Link>
        </div>
      </div>
      <ul className='flex flex-col gap-3 p-3'>
        {/* <li className='flex flex-row w-full justify-around items-center '>
            <p className='text-[18px]'> الأطباء</p>
            <img src={doctor_icon} alt='doctor_icon'/>
        </li>
        <li className='flex flex-row w-full justify-around items-center '>
            <p className='text-[18px]' >المتجر</p>
            <img src={chart_icon} alt='chart_icon'/>
        </li> */}
        <hr className="w-full bg-[--main-color-green!important] h-[1.5px] my-3" />
        <div className='my-2'>
            <button className='flex flex-row justify-between w-full items-center' onClick={logout}>
                <p className='text-[18px]'>تسجيل الخروج</p>
                <img src={logout_icon} alt='logout_icon'/>
            </button>
            {error && (<p className='text-red-500'>{error}</p>)}
        </div>
      </ul>
    </div>
  )
}

export default SignedUser
