import React from 'react';
import { Outlet } from 'react-router';
import Cookies from 'js-cookie'
import ErrorNotFound from '../../Components/ErrorNotFound';
import ErrorUnauthorized from '../../Components/ErrorUnauthorized';
const RequiredRoles = ({ allowedRoles }) => {

const role = Cookies.get('userRole');
const token = Cookies.get('adminToken') || Cookies.get('userToken');

    // Check if the current user's role is allowed
    return (
      allowedRoles.includes(role) ? (
        <Outlet />
      ) : !token ?(
        <ErrorUnauthorized/>
      ):(
        <>
        <ErrorNotFound/>
        </>
      )
    );
}

export default RequiredRoles;
