import React, { createContext, useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

export const GetHospitalAdminData = createContext();

const ContextGetData = ({ children }) => {
    const [hospitalData, setHospitalData] = useState(null);
    const [error, setError] = useState(null);
    const [forbidden, setErrorForbidden] = useState(false)
    const [errorStatus, setErrorStatus] = useState(null);

    const token = Cookies.get('adminToken');
    const userRole = Cookies.get('userRole')
    const fetchHospitalData = useCallback(async () => {
        const token = Cookies.get('adminToken');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}clients/hospital/my-profile`, {
                headers: {
                    'Accept': '*/*',
                    'Authorization': `Bearer ${token}`
                }
            });
            setHospitalData(response.data.data);
            setError(null);
            setErrorStatus(null);
        } catch (err) {
            handleError(err);
        }
    },[])

    useEffect(() => {
        if (token && userRole === 'Client Admin') {
            fetchHospitalData();
        }
    }, [token,userRole,fetchHospitalData]); // Trigger re-fetch when token changes

    const handleError = (err) => {
        if(err?.response?.status === 403){
            setErrorForbidden(true);
        }else{
            setError('حدث خطأ.')
        }
    };

    const clearHospitalData = () => {
        setHospitalData(null);
        setError(null);
        setErrorStatus(null);
    };

    return (
        <GetHospitalAdminData.Provider
            value={{ 
                hospitalData,
                error,
                errorStatus,
                forbidden,
                refetchHospitalData: fetchHospitalData,
                clearHospitalData
            }}
        >
            {children}
        </GetHospitalAdminData.Provider>
    );
};

export default ContextGetData;
